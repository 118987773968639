<template>
  <div class="notification-icon-create-offer" />
</template>

<style scoped>
.notification-icon-create-offer {
  width: 25px;
  height: 24px;
  background: center / contain no-repeat
    url("~@/assets/images/icons/notification/notification-create-offer.png");
}

@media (max-width: 1200px) {
  .notification-icon-create-offer {
    width: 22px;
    height: 22px;
  }
}
</style>
